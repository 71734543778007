@use 'styles/breakpoints' as *;

.container {
  // !important overrides display: grid; from pageComponents/container/container.module.scss
  display: flex !important;
  flex-direction: column;

  @include desktop {
    flex-direction: row;
    gap: 5rem;
  }
}

.textBlock {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;

  .header {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
  }
}
