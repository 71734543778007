@use 'styles/breakpoints' as *;

.showCase {
  --sc-case-margin: 0.5rem;
  --sc-title-margin: 0.875rem;
  --sc-description-margin: 0.875rem;
  --sc-btn-margin: 1.5rem;

  display: grid;
  width: 100%;
  gap: 2rem;

  grid-template-areas:
    'content'
    'image';

  @include desktop {
    --sc-case-margin: 2rem;
    --sc-title-margin: 1.625rem;
    --sc-description-margin: 1.625rem;
    --sc-btn-margin: 3rem;

    grid-template-areas: 'content image';
    grid-template-columns: 4fr 6fr;
    align-items: center;
    gap: 5rem;
  }
}
.image {
  grid-area: image;
  width: 100%;
  position: relative;
  padding-top: 100%;
}

.content {
  grid-area: content;
  display: grid;
  justify-items: start;
}

.case {
  color: var(--clr-primary);
  margin-bottom: var(--sc-case-margin);
}

.title {
  margin-bottom: var(--sc-title-margin);
}

.description {
  margin-top: var(--sc-description-margin);
}

.button {
  margin-top: var(--sc-btn-margin);
}
