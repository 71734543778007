@use './shared.module.scss' as *;

.button {
  @include defaultButtonStyle;

  font-family: var(--inter-font);
  font-size: var(--btn-font-size);
  line-height: 1;
  font-weight: 700;
  position: relative;
  letter-spacing: 0;
  color: var(--text-color);
  gap: var(--btn-gap);
  display: inline-flex;
  padding: 0;

  &:active {
    .wrapper {
      transform: scale(0.95);
    }
  }

  @include variants;

  &.primary,
  &.secondary,
  &.tertiary {
    padding: var(--btn-padding-y) var(--btn-padding-x);

    &::before,
    &::after {
      --morph-size: var(--btn-morph-size-default);
      content: '';
      box-sizing: border-box;
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;
      transition: all var(--transition-duration) ease;
    }

    &::before {
      background-color: var(--border-color);
      z-index: -1;
      clip-path: polygon(
        /* left top */ 0% calc(0% + var(--morph-size)),
        /* left bottom */ 0% calc(100% - var(--morph-size)),
        /* left bottom inner 1 */ calc(0% + var(--btn-border-size))
          calc(100% - var(--morph-size)),
        /* left middle top inner */ calc(0% + var(--btn-border-size))
          calc(calc(0% + var(--morph-size)) + var(--btn-border-size)),
        /* right middle top inner */ calc(100% - var(--btn-border-size))
          calc(0% + var(--btn-border-size)),
        /* right middle bottom inner */ calc(100% - var(--btn-border-size))
          calc(100% - var(--btn-border-size)),
        /* left middle bottom inner */ calc(0% + var(--btn-border-size))
          calc(calc(100% - var(--morph-size)) - var(--btn-border-size)),
        /* left bottom inner 2 */ 0% calc(100% - var(--morph-size)),
        /* right bottom */ 100% 100%,
        /* right top */ 100% 0%
      );
    }

    &::after {
      background-color: var(--bg-color);
      z-index: -2;
      clip-path: polygon(
        0% calc(0% + calc(var(--morph-size)) * 1),
        100% 0%,
        100% 100%,
        0% calc(100% - calc(var(--morph-size)) * 1)
      );
    }

    &:hover {
      &::before,
      &::after {
        --morph-size: var(--btn-morph-size-enlarged);
        inset: calc(-0.5 * var(--morph-size)) 0;
        height: calc(100% + calc(1 * var(--morph-size)));
      }
    }
  }

  &.disabled {
    @include disabled;
  }
}
