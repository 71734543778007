@use 'styles/breakpoints' as *;

.testimonial {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: flex-start;
  width: var(--testimonial-width);
  margin: auto;
  height: 100%;

  &.noLogo {
    gap: 1.5rem;

    .quote {
      margin-top: var(--testimonial-no-logo-quote-margin);
      font-size: var(--testimonial-no-logo-font-size);
    }
  }
  &.logo {
    gap: var(--testimonial-logo-gap);

    .quote {
      font-size: var(--testimonial-logo-font-size);
    }
  }

  .company {
    height: var(--testimonial-logo-logo-height);
    width: 100%;
    position: relative;
  }

  .companyLogo {
    object-fit: contain;
    object-position: left;
  }

  .icon {
    display: block;
    background-color: currentColor;
    mask-image: url('/assets/quote.svg');
    mask-position: left center;
    mask-repeat: no-repeat;
    height: var(--testimonial-no-logo-icon-height);
    width: 100%;

    @include desktop {
      position: absolute;
      right: calc(100% + 5.5rem);
      width: 12rem;
    }
  }

  .quote {
    line-height: 1.8;
    font-weight: 600;
  }

  .author {
    margin-top: auto;
  }
}
