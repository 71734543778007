@use 'styles/breakpoints' as *;

.carouselWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
}

.carouselHeaderWrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 2.5rem;
  max-width: 105rem;

  @include desktop {
    margin-bottom: 5rem;
  }

  & button {
    height: fit-content;
    white-space: nowrap;
  }
}

.carouselHeader {
  display: flex;
  flex-direction: column;
  max-width: 57.5rem;
  gap: 1.5rem;

  @include desktop {
    gap: 2rem;
  }
}

.itemsWrapper {
  display: flex;
  flex-direction: row;
  gap: 1rem;

  @include desktop {
    gap: 3rem;
  }
}

.headerButton {
  display: none;

  @include desktop {
    display: flex;
  }
}

.mobileButton {
  display: flex;
  width: fit-content;
  margin-top: 2.5rem;
  align-self: center;

  @include desktop {
    display: none;
  }
}
