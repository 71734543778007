@use 'styles/breakpoints' as *;

.contactCard {
  display: flex;
  align-items: flex-start;
  flex-grow: 1;
  flex-direction: column;

  @include desktop {
    flex-direction: row;
  }

  .imageWrapper {
    background: var(--clr-light-gray);
    padding: 1.5rem 1.5rem 0;
    width: 100%;
    display: flex;
    align-items: flex-start;
    align-self: stretch;

    @include desktop {
      padding: 0;
      width: 13.75rem;
    }

    .image {
      width: 6.4375rem;
      height: 6.4375rem;
      object-fit: cover;
      object-position: center;
      border-radius: 100%;

      @include desktop {
        border-radius: 0;
        width: 13.75rem;
        height: 100%;
      }
    }
  }

  .content {
    padding: 1.5rem;
    background: var(--clr-light-gray);
    width: 100%;
    display: flex;
    padding: 1.5rem 1.5rem 1.75rem 1.5rem;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    gap: 0.5rem;

    @include desktop {
      gap: 0.75rem;
      padding: 2rem 2.25rem 2.75rem 2.25rem;
      flex: 1 0 0;
    }

    .heading {
      display: flex;
      flex-direction: column;
      gap: 0.125rem;

      @include desktop {
        gap: 0.25rem;
      }
    }

    .body,
    .heading {
      white-space: pre-wrap;
    }

    .actions {
      display: flex;
      flex-direction: column;
      row-gap: 0.5rem;
      column-gap: 1.25rem;
      flex-wrap: wrap;
      max-height: 4.5rem;

      @include desktop {
        row-gap: 0.75rem;
        column-gap: 3rem;
        max-height: 6.5rem;
      }
    }
  }
}
