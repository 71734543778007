@use 'styles/breakpoints' as *;

.getInTouchContainer {
  display: flex;
  flex-direction: column;

  gap: 3rem;

  @include desktop {
    width: 100%;
  }

  .contactCardGrid {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;

    @include desktop {
      gap: 2rem;
    }

    .general,
    .people {
      display: grid;
      gap: 1.25rem;

      @include desktop {
        gap: 2rem;
      }
    }

    .people {
      @include desktop {
        grid-template-columns: 1fr 1fr;
      }
    }

    .general {
      @include desktop {
        grid-template-columns: 24.0625rem 24.0625rem auto;
      }
    }
  }
}
