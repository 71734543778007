@use 'styles/breakpoints' as *;

.label {
  display: flex;
  position: relative;
  padding: 0.25rem 0;

  &:after,
  &::before {
    position: absolute;
    content: '';
    display: block;
    width: 100%;
    height: 0.125rem;
    left: 0;
    bottom: 0;
    transform: scaleX(1);
    transition: transform 0.3s;

    @include desktop {
      height: 0.25rem;
    }
  }

  &::before {
    background: var(--clr-dark-gray-faded);
  }

  &::after {
    background: var(--clr-dark-gray);
    transform: scaleX(0);
    transform-origin: left center;
    transition: transform 0.2s ease;
  }

  &.inverted {
    &::before {
      background: transparent;
    }

    &::after {
      background: var(--clr-white);
    }
  }

  &:hover::after {
    transform: scaleX(1);
  }
}
