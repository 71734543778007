@use 'styles/breakpoints' as *;

.mediaEmbed {
  position: relative;
  display: flex;
  max-width: 800px;
  width: 100%;
  margin-inline: auto;

  &.portrait {
    flex-direction: row-reverse;
    align-items: center;

    &.underlay {
      padding: 0 7.5rem;

      @include desktop {
        .underlayment {
          display: block;
          left: 0;
          width: 100%;

          top: 5rem;
          height: calc(100% - 10rem);
        }
      }
    }
  }

  &.landscape {
    flex-direction: column;
    align-items: flex-start;

    gap: var(--media-landscape-gap);

    .content {
      padding: 0 var(--media-landscape-content-padding);
    }

    @include desktop {
      max-width: 80rem;

      &.underlay {
        padding: var(--media-landscape-underlay-padding) 0;

        .underlayment {
          display: block;

          left: calc(var(--media-landscape-underlay-padding) / 2);
          width: calc(100% - var(--media-landscape-underlay-padding));
          top: 0;
          height: 100%;
        }
      }
    }
  }

  &.spotify {
    align-items: center;

    @include desktop {
      &.underlay {
        &::before {
          content: '';
          position: absolute;
          clip-path: polygon(0 5%, 100% 0, 100% 100%, 0 95%);
          background-color: var(--clr-primary);
          left: calc(var(--media-landscape-underlay-padding) / 2);
          top: 0;
          height: 100%;
          width: calc(100% - var(--media-landscape-underlay-padding));
        }
      }
    }
  }

  &.fit {
    // height: auto;
    align-self: normal;
  }
}

.mediaImage,
.mediaVideo {
  display: block;
  width: 100%;
  position: relative;
  overflow: hidden;
  border: 0;

  img {
    object-position: center;
  }

  &.portrait,
  &.landscape {
    img {
      object-fit: cover;
    }
  }

  &.portrait {
    aspect-ratio: 14/25;
  }
  &.landscape {
    aspect-ratio: 16/9;
  }

  &.fit {
    img {
      object-fit: contain;
      position: relative !important;
    }
  }
}

.underlayment {
  display: none;
  position: absolute;
  clip-path: polygon(0 0, 100% 5%, 100% 95%, 0 100%);
  background-size: cover;
  transform: scaleX(-100%);
  opacity: 0.2;
  filter: blur(0.75rem);
}

.mediaSpotify {
  width: 100%;
  height: 14.5rem;
  position: relative;
  border: none;
}

.spotifyContent {
  display: grid;
  gap: 1rem;
  direction: ltr;

  @include desktop {
    gap: 2.5rem;
  }
}
