@use 'styles/breakpoints' as *;

.container {
  .tile {
    height: 100%;
    overflow: visible;
  }
}

.tile {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: var(--tile-gap);
  overflow: hidden;
}

.flipbookContainer {
  padding: 4rem 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.friendBookIntro {
  line-height: 1.5;
  font-size: 1.5rem;
  gap: var(--slope-inner-gap);
  padding: var(--slope-padding) 0;
  margin-top: calc(-1 * var(--slope-height));

  h2 {
    margin-bottom: 2rem;
  }
}

.cover {
  padding: 1rem 1.5rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 1200px;
  border-radius: 10px;
}

.coverLeft,
.coverRight {
  background-color: var(--clr-primary);
  position: absolute;
  height: 100%;
  width: 50%;
  z-index: 1;
}

.coverLeft {
  top: 0;
  left: 0;
  border-radius: 10px 0 0 10px;
  -webkit-box-shadow: inset 45px 10px 58px 0px rgba(0, 0, 0, 0.24);
  -moz-box-shadow: inset 45px 10px 58px 0px rgba(0, 0, 0, 0.24);
  box-shadow: inset 45px 10px 58px 0px rgba(0, 0, 0, 0.24);
}

.coverRight {
  top: 0;
  right: 0;
  border-radius: 0 10px 10px 0;
  -webkit-box-shadow: inset -45px 10px 58px 0px rgba(0, 0, 0, 0.24);
  -moz-box-shadow: inset -45px 10px 58px 0px rgba(0, 0, 0, 0.24);
  box-shadow: inset -45px 10px 58px 0px rgba(0, 0, 0, 0.24);
}

.layersLeft,
.layersRight {
  position: absolute;
  width: 50%;
  z-index: 3;
  background-image: repeating-linear-gradient(90deg, #ede6c2, #c4bfa4 0.3%);
}

.layersLeft {
  left: 0;
  border-radius: 50% / 1% 5% 5% 1%;
  margin-left: 1rem;
}

.layersRight {
  right: 0;
  margin-left: 0.5rem;
  border-radius: 50% / 5% 1% 1% 5%;
  margin-right: 1rem;
}

.flipbook {
  z-index: 3;
}

.row {
  display: flex;
  justify-content: space-between;
  overflow: hidden;
}

.imageContainer {
  display: flex;
  position: relative;
  min-width: 175px;
  min-height: 175px;
  max-width: 200px;
  max-height: 200px;
  background-position: bottom;

  img {
    display: block;
  }
}

.round {
  overflow: hidden;
  border-radius: 50%;
}

.image {
  object-position: center;
  object-fit: contain;
}

.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: var(--tile-inner-gap);
  padding: 0 var(--tile-inner-paddding);
}

.questions {
  font-family: var(--inter-font);
  font-size: 16px;

  div {
    margin: 0.5rem 0;
    padding-bottom: 0.25rem;
    border-bottom: 0.5px solid rgb(57, 57, 57);
  }
}

.page::-webkit-scrollbar {
  display: none;
}

.page {
  position: relative;
  text-align: left;
  padding: 2rem;
  overflow-y: scroll;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  cursor: pointer;

  h3 {
    font-family: var(--neue-plak-font);
    text-transform: uppercase;
    font-size: 2rem;
    font-weight: 200;
    margin-bottom: 0.5rem;
    margin-top: 1rem;
  }

  .capiImage {
    object-fit: cover;
    max-width: 200px;
  }

  .image2 {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 50%;
    height: 200px;
    opacity: 0.3;
  }

  .image3 {
    position: absolute;
    inset: 0;
    opacity: 0.1;
    object-fit: cover;
    z-index: -1;
    img {
      object-fit: cover;
    }
  }

  &.left,
  &.right {
    border-radius: unset;
  }

  &.template-dinosaur {
    background: #fff9d5;
    &.left {
      box-shadow: inset -10px 0 10px #ddd8bb;
    }
    &.right {
      box-shadow: inset 10px 0 10px #ddd8bb;
    }
  }
  &.template-universe {
    background: #021454;
    &.left {
      box-shadow: inset -10px 0 10px #5d6a98;
    }
    &.right {
      box-shadow: inset 10px 0 10px #5d6a98;
    }
    color: white;
    .questions div {
      border-color: white;
    }
  }
  &.template-princesses {
    background: #d73d79;
    &.left {
      box-shadow: inset -10px 0 10px #951c4a;
    }
    &.right {
      box-shadow: inset 10px 0 10px #651332;
    }
    color: white;
    .questions div {
      border-color: white;
    }
  }
  &.template-marvel {
    background: #dc4333;
    &.left {
      box-shadow: inset -10px 0 10px #972d21;
    }
    &.right {
      box-shadow: inset 10px 0 10px #972d21;
    }
    color: white;
    .questions div {
      border-color: white;
    }
  }
  &.template-animals {
    background: #bad5b4;
    &.left {
      box-shadow: inset -10px 0 10px #839f7e;
    }
    &.right {
      box-shadow: inset 10px 0 10px #839f7e;
    }
  }
  &.template-mariokart {
    background: #4d2c82;
    &.left {
      box-shadow: inset -10px 0 10px #231932;
    }
    &.right {
      box-shadow: inset 10px 0 10px #231932;
    }
    color: white;
    .questions div {
      border-color: white;
    }
  }
}

.bookNavigation {
  button {
    margin: 1rem 0;
    font-size: 16px;
  }
}

@include desktop {
  .page {
    font-size: 12px;
  }

  .coverMiddle {
    background-color: var(--clr-dark-gray);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100%;
    width: 100px;
    z-index: 2;
    border-radius: 2px;
  }

  .imageContainer {
    min-width: 125px;
    min-height: 125px;
    max-width: 150px;
    max-height: 150px;
  }

  .questions {
    font-size: 12px;
  }

  .flipbookContainer {
    padding-top: 0;
  }
}
