@use 'styles/breakpoints' as *;

.form {
  --gap: 1.5rem;
  position: relative;
  display: grid;
  gap: var(--gap);

  @include desktop {
    --gap: 2.5rem;
    min-width: 50%;
  }
}

.name {
  display: grid;
  gap: var(--gap);

  @include desktop {
    grid-template-columns: (repeat(2, 1fr));
  }
}

.submit {
  @include desktop {
    justify-self: start;
  }
}
