@use 'styles/breakpoints' as *;

.getInTouchContainer {
  display: flex;
  flex-direction: column;
  gap: 3rem;

  @include desktop {
    flex-direction: row;
    gap: 5rem;
  }

  .textBlock {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    @include desktop {
      max-width: 30rem;
      gap: 2.5rem;
    }
  }

  .formSelector {
    width: 100%;

    @include desktop {
      width: 45rem;
    }
  }

  .formSelectorDrawer {
    width: 100%;
  }

  .contactButton span {
    padding: 0;
  }

  .linkButton {
    display: inline-flex;
  }
}
