@use 'styles/breakpoints' as *;

.textBlock {
  display: grid;
  gap: var(--text-block-gap);
  color: var(--clr-dark-gray);

  &.center {
    text-align: center;
  }

  &.right {
    text-align: right;
  }

  &.column {
    align-items: flex-start;

    @include desktop {
      grid-template-columns: auto max-content;
      grid-column-gap: 15rem;

      .button {
        margin-top: 1.25rem;
      }
    }
  }
}

.copy {
  display: grid;
  gap: var(--text-block-gap);
  align-items: flex-start;
}

.button {
  justify-self: start;
}
