@use 'styles/breakpoints' as *;

.jobListItem {
  display: grid;
  color: var(--clr-dark-gray);
  position: relative;
  text-align: left;
  padding: 2rem 0;
  width: 100%;

  grid-template-areas:
    'title  icon'
    'subtitle   x'
    'description    description';
  grid-template-columns: auto 2.5rem;
  grid-template-rows: auto 1fr;

  &::before,
  &::after {
    content: '';
    position: absolute;
    height: 0.25rem;
    left: 0;
    bottom: 0;
  }

  &::before {
    background-color: var(--clr-dark-gray-faded);
    width: 100%;
  }

  &::after {
    background-color: var(--clr-dark-gray);
    width: 0;
    transition: width var(--transition-duration) ease;
  }

  &:hover {
    &::after {
      width: 100%;
    }
  }

  .title,
  .subtitle {
    font-size: 1.25rem;
    line-height: 1.5;
    font-weight: 700;
  }
  .title {
    grid-area: title;
    margin-bottom: 0.25rem;
  }

  .subtitle {
    grid-area: subtitle;
    opacity: 0.4;
  }

  .description {
    margin-top: 1.25rem;
    grid-area: description;
  }

  .icon {
    grid-area: icon;
    justify-self: end;
  }

  .growingArrow {
    display: none;
  }

  @include desktop {
    padding: 4rem 0;

    .title,
    .subtitle {
      font-size: 2rem;
    }

    .title {
      margin-bottom: 0.5rem;
    }

    .description {
      margin-top: 0;
    }

    &.row {
      grid-template-areas:
        'title description icon'
        'subtitle description x';

      grid-template-columns: 1fr 1fr 8rem;

      &.noDescription {
        grid-template-areas:
          'title icon'
          'subtitle x';

        grid-template-columns: 1fr 8rem;
      }
    }

    &.column {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .subtitle {
        margin-bottom: 1.75rem;
      }

      .description {
        margin-bottom: 1.5rem;
      }

      &::before,
      &::after,
      .icon {
        display: none;
      }

      .growingArrow {
        display: block;
      }
    }
  }
}
