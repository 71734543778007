@use 'styles/breakpoints' as *;

.linkButton {
  display: flex;
  align-items: center;
  gap: 0.25rem;

  @include desktop {
    gap: 0.5rem;
  }

  .label {
    position: relative;
    padding: 0.25rem 0;

    &:after,
    &::before {
      position: absolute;
      content: '';
      display: block;
      width: 100%;
      height: 0.125rem;
      left: 0;
      bottom: 0;
      transform: scaleX(1);
      transition: transform 0.3s;

      @include desktop {
        height: 0.25rem;
      }
    }

    &::before {
      background: var(--clr-dark-gray-faded);
    }

    &::after {
      background: var(--clr-dark-gray);
      transform: scaleX(0);
      transform-origin: left center;
      transition: transform 0.2s ease;
    }

    &:hover::after {
      transform: scaleX(1);
    }
  }

  .leadingIcon,
  .trailingIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.25rem;
    height: 1.25rem;

    @include desktop {
      width: 1.75rem;
      height: 1.75rem;
    }
  }
}
