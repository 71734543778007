@use 'styles/breakpoints' as *;

.grid {
  columns: 1 auto;
  gap: 2rem;

  @include desktop {
    columns: 4 auto;
  }
}

.item {
  page-break-inside: avoid;
  break-inside: avoid;
  display: table;

  margin-bottom: -2rem;
  padding-bottom: 2rem;

  margin-top: -2rem;
  padding-top: 2rem;

  &:nth-child(1) {
    margin-top: 0rem;
  }
}

.header {
  text-align: center;
}
