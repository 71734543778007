@use 'styles/breakpoints' as *;

.simpleList {
  display: grid;
  align-content: baseline;

  &.largeGap {
    gap: 2.5rem;
  }

  @include desktop {
    &.cols {
      grid-template-columns: repeat(2, 1fr);
    }

    &.largeGap {
      gap: 5rem;
    }
  }
}

.icon {
  height: var(--list-item-icon-size);
}

.illustration {
  height: var(--list-item-illustration-size);
}

.listWrapper {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  @include desktop {
    gap: 3rem;
  }
}
