@use 'styles/breakpoints' as *;

.testimonial {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  position: relative;

  width: 17.5rem;
  padding: 1.75rem;

  @include desktop {
    width: 100%;
    padding: 3.5rem;
  }

  &.grid {
    width: unset;
    padding: 1.5rem;

    @include desktop {
      padding: 2.5rem;
    }
  }

  &::before {
    background-color: var(--clr-light-gray);
    content: '';
    position: absolute;
    --morph-size: 0%;
    inset: 0;
    width: 100%;
    height: 100%;
    transition: all var(--transition-duration) ease;
    z-index: -1;
    clip-path: polygon(
      100% calc(0% + calc(var(--morph-size)) * 1),
      0% 0%,
      0% 100%,
      100% calc(100% - calc(var(--morph-size)) * 1)
    );
  }

  &.hasPage {
    &:hover {
      &::before {
        --morph-size: 5%;
        inset: calc(-0.5 * var(--morph-size)) 0;
        height: calc(100% + calc(1 * var(--morph-size)));
        width: 105%;
        left: -2.5%;
      }
    }
  }
}

.logo {
  height: 2rem;
  max-width: 5.625rem;
  object-fit: contain;
  align-self: flex-start;
  width: auto;

  @include desktop {
    height: 3.5rem;
    max-width: 10rem;
  }

  &.grid {
    height: 1.75rem;

    @include desktop {
      height: 2.3125rem;
      max-width: 7.5rem;
    }
  }
}

.quote {
  margin: 1.5rem 0 2.75rem 0;

  @include desktop {
    margin: 2rem 0 3rem 0;
  }

  &.grid {
    margin: 0.75rem 0 1.5rem 0;

    @include desktop {
      margin: 1.5rem 0 2.5rem 0;
    }
  }
}

.author {
  margin-top: auto;
  display: grid;
  grid-template:
    'name . arrow' auto
    'title company arrow' auto
    / auto auto 1fr;
  row-gap: 0.125rem;
  column-gap: 0.75rem;

  @include desktop {
    row-gap: 0.5rem;
    column-gap: 1rem;
  }

  .name {
    grid-area: name;
  }

  .title {
    color: #1e1e1e66;
    grid-area: none;
    display: none;

    @include desktop {
      display: block;
      grid-area: title;
    }

    &.grid {
      grid-area: none;
      display: none;
    }
  }

  .company {
    grid-area: title;

    @include desktop {
      grid-area: company;
    }

    &.grid {
      grid-area: title;
    }
  }

  .arrow {
    grid-area: arrow;
    justify-self: flex-end;
    align-self: flex-end;
  }
}
