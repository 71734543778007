@use 'styles/breakpoints' as *;

.button {
  font-family: var(--inter-font);
  font-size: var(--btn-font-size);
  line-height: 1;
  font-weight: 700;
  position: relative;
  letter-spacing: 0;
  color: var(--text-color);
  gap: var(--btn-gap);
  display: inline-flex;
  padding: 0;

  &:active {
    .wrapper {
      transform: scale(0.95);
    }
  }

  &.primary {
    --bg-color: var(--clr-primary);

    &.neutral {
      --text-color: var(--clr-white);
    }
    &.inverted {
      --text-color: var(--clr-dark-gray);
    }
  }

  &.secondary {
    --bg-color: var(--clr-dark-gray);
    --text-color: var(--clr-white);

    &.neutral {
      --bg-color: var(--clr-dark-gray);
      --text-color: var(--clr-white);
    }
    &.inverted {
      --bg-color: var(--clr-white);
      --text-color: var(--clr-dark-gray);
    }
  }

  &.tertiary {
    --bg-color: transparent;
    --text-color: var(--clr-dark-gray);
    --border-color: var(--clr-dark-gray);

    &.neutral {
      --text-color: var(--clr-dark-gray);
      --border-color: var(--clr-dark-gray);
    }
    &.inverted {
      --text-color: var(--clr-white);
      --border-color: var(--clr-white);
    }
  }

  &.underline {
    &.neutral {
      --text-color: var(--clr-dark-gray);
      --bg-color: var(--clr-dark-gray-faded);
      --border-color: var(--clr-dark-gray);
    }
    &.inverted {
      --text-color: var(--clr-white);
      --bg-color: rgba(255, 255, 255, 0.3);
      --border-color: var(--clr-white);
    }

    &.active,
    &:hover {
      .wrapper::after {
        width: 100%;
      }
    }

    .wrapper {
      position: relative;
      transition: all var(--transition-duration) ease;
      padding: var(--btn-padding-y) 0;

      &::before,
      &::after {
        content: '';
        position: absolute;
        display: block;
        left: 0;
        bottom: 0.75rem;
        height: var(--btn-border-size);
      }

      &::before {
        background: var(--bg-color);
      }

      &::after {
        background-color: var(--border-color);
        width: 0%;
        transition: width 0.2s ease;
      }
    }

    &:not(.isNav) .wrapper::before {
      width: 100%;
    }
  }

  &.primary,
  &.secondary,
  &.tertiary {
    padding: var(--btn-padding-y) var(--btn-padding-x);

    &::before,
    &::after {
      --morph-size: var(--btn-morph-size-default);
      content: '';
      box-sizing: border-box;
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;
      transition: all var(--transition-duration) ease;
    }

    &::before {
      background-color: var(--border-color);
      z-index: -1;
      clip-path: polygon(
        /* left top */ 0% calc(0% + var(--morph-size)),
        /* left bottom */ 0% calc(100% - var(--morph-size)),
        /* left bottom inner 1 */ calc(0% + var(--btn-border-size))
          calc(100% - var(--morph-size)),
        /* left middle top inner */ calc(0% + var(--btn-border-size))
          calc(calc(0% + var(--morph-size)) + var(--btn-border-size)),
        /* right middle top inner */ calc(100% - var(--btn-border-size))
          calc(0% + var(--btn-border-size)),
        /* right middle bottom inner */ calc(100% - var(--btn-border-size))
          calc(100% - var(--btn-border-size)),
        /* left middle bottom inner */ calc(0% + var(--btn-border-size))
          calc(calc(100% - var(--morph-size)) - var(--btn-border-size)),
        /* left bottom inner 2 */ 0% calc(100% - var(--morph-size)),
        /* right bottom */ 100% 100%,
        /* right top */ 100% 0%
      );
    }

    &::after {
      background-color: var(--bg-color);
      z-index: -2;
      clip-path: polygon(
        0% calc(0% + calc(var(--morph-size)) * 1),
        100% 0%,
        100% 100%,
        0% calc(100% - calc(var(--morph-size)) * 1)
      );
    }

    @include desktop {
      &:hover {
        &::before,
        &::after {
          --morph-size: var(--btn-morph-size-enlarged);
          inset: calc(-0.5 * var(--morph-size)) 0;
          height: calc(100% + calc(1 * var(--morph-size)));
        }
      }
    }
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}
