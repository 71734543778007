.videoPlayer {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.video {
  background-color: var(--clr-dark-gray);
  object-fit: contain;
  object-position: center;
  width: 100%;
  height: 100%;
  position: absolute;
}

.poster {
  width: 100%;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
}

.thumbnail {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.playButton {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%);
}

.logo {
  position: absolute;
  bottom: var(--media-logo-padding);
  right: var(--media-logo-padding);
  color: var(--clr-white);
  width: var(--media-logo-width);
}
