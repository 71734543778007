@use 'styles/breakpoints' as *;

$input-icon-gap: 1.25rem;
$input-border-width: 0.25rem;

.textFieldWrapper {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;

  --input-padding-x: 1rem;

  @include desktop {
    --input-padding-x: 1.5rem;
  }
}

.textField {
  position: relative;
  display: flex;
  gap: $input-icon-gap;
  padding: 0;
  color: var(--clr-neutral-default-base);

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    border-bottom: var(--input-border-width) solid var(--clr-dark-gray-faded);
    transform: scaleY(0);
    left: 0;
    right: 0;
    width: 100%;
    transition: all var(--transition-duration) ease;
    transform-origin: bottom center;
  }

  &:hover {
    &::after {
      transform: scaleY(1);
    }
  }

  &.focused {
    &::after {
      transform: scaleY(1);
      border-color: var(--clr-primary);
    }
  }

  &.error {
    &::after {
      transform: scaleY(1);
      border-color: var(--clr-error);
    }
  }
}

.label {
  position: absolute;
  transform-origin: left top;
  top: 1.1rem;
  left: var(--input-padding-x);
  transition: all var(--transition-duration) ease;

  @include desktop {
    top: 1.49rem;
  }

  &.hasIcon {
    left: auto;
  }

  &.active,
  &.filled {
    top: 0.62rem;
    scale: 0.75;
    line-height: 1.3;

    @include desktop {
      top: 0.75rem;
    }
  }

  &.focused {
    color: #13ca91;
  }

  &.filled {
    color: var(--clr-dark-gray-muted);
  }

  &.error {
    color: var(--clr-error);
  }
}

.inputContainer {
  width: 100%;

  &.multiline {
    display: grid;

    &::after {
      content: attr(data-replicatedvalue) ' ';
      white-space: pre-wrap;
      visibility: hidden;
    }
  }
}

.input,
.inputContainer.multiline::after {
  font-family: var(--inter-font);
  background-color: var(--clr-light-gray);

  width: 100%;
  outline: 0;
  font-weight: 500;
  border: 0;
  box-sizing: border-box;
  grid-area: 1 / 1 / 2 / 2;

  font-size: 1rem;
  line-height: 1.5rem;
  padding: 1.5rem var(--input-padding-x) 0.625rem;

  @include desktop {
    font-size: 1.5rem;
    line-height: 2.25rem;
    padding: 2.25rem var(--input-padding-x) 0.75rem;
  }

  &.multiline {
    overflow: hidden;
    resize: none;
    padding: 2.25rem var(--input-padding-x) 1.25rem;
  }
}
