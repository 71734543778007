@use 'styles/breakpoints' as *;

.uploadFieldWrapper {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.uploadField {
  --clr-border: #dddddd;
  --border-width-sm: 0.8rem;
  --border-width-lg: 1.25rem;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  background-position: 0 0, 0 0, 100% 0, 0 100%;
  background-size: 0.2rem calc(100% + 1.75rem), calc(100% + 1.75rem) 0.2rem,
    0.2rem calc(100% + 1.75rem), calc(100% + 1.75rem) 0.2rem;
  background-repeat: no-repeat;
  background-image: repeating-linear-gradient(
      0deg,
      var(--clr-border),
      var(--clr-border) var(--border-width-sm),
      transparent var(--border-width-sm),
      transparent var(--border-width-lg)
    ),
    repeating-linear-gradient(
      90deg,
      var(--clr-border),
      var(--clr-border) var(--border-width-sm),
      transparent var(--border-width-sm),
      transparent var(--border-width-lg)
    ),
    repeating-linear-gradient(
      180deg,
      var(--clr-border),
      var(--clr-border) var(--border-width-sm),
      transparent var(--border-width-sm),
      transparent var(--border-width-lg)
    ),
    repeating-linear-gradient(
      270deg,
      var(--clr-border),
      var(--clr-border) var(--border-width-sm),
      transparent var(--border-width-sm),
      transparent var(--border-width-lg)
    );

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  padding: 2.5rem 0;
  animation: boxBorderAnimation 1s infinite linear paused;

  @include desktop {
    padding: 4rem 0;
  }

  &.error {
    --clr-border: var(--clr-error);
  }

  &:hover {
    animation-play-state: running;
  }

  @keyframes boxBorderAnimation {
    from {
      background-position: 0 0, -20px 0, 100% -20px, 0 100%;
    }
    to {
      background-position: 0 -20px, 0 0, 100% 0, -20px 100%;
    }
  }
}

.fileInput {
  height: 0;
  width: 0;
  position: absolute;
  display: none;
}

.dragContent {
  display: flex;
  align-items: center;
  gap: 0.75rem;

  @include desktop {
    flex-direction: column;
    gap: 1.25rem;
  }
}

.illustration {
  grid-area: icon;
  height: 5rem;

  @include desktop {
    height: 5.5rem;
  }

  &.error {
    color: var(--clr-error);
  }
}

.uploadButton {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  max-width: 9.75rem;

  @include desktop {
    gap: 0.25rem;
    max-width: 100%;
    align-items: center;
    text-align: center;
  }

  span {
    display: flex;
  }

  span.dragToText {
    display: none;

    @include desktop {
      display: inherit;
      margin-left: 0.3rem;
    }
  }

  span.underline {
    text-decoration: underline;
  }
}
