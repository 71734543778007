.author {
  display: flex;
  gap: 0.75rem;
}

.image {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 0.12rem;
}
