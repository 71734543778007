@use 'styles/breakpoints' as *;

.stats {
  display: grid;
  justify-content: center;
  width: min-content;
  gap: var(--stats-gap);
  margin: auto;

  @include desktop {
    grid-template-columns: repeat(3, 1fr);
  }
}

.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--stats-item-gap);
}

.percentage {
  font-size: var(--stats-percentage-size);
  line-height: 0.8;
  color: var(--clr-primary);
  font-family: var(--neue-plak-font);
  font-weight: 400;
}

.label {
  font-size: var(--stats-copy-size);
  line-height: 1.5;
  font-weight: 600;
}
