.container {
  .tile {
    height: 100%;
    overflow: visible;
  }
}

.tile {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: var(--tile-gap);
  overflow: hidden;
}

.imageContainer {
  position: relative;
  width: 100%;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  transition: all var(--transition-duration) ease;

  &.square {
    aspect-ratio: 1/1;
  }

  &.tall {
    aspect-ratio: 6/7;
  }

  &.hovering {
    --image-negative: calc(-1 * var(--tile-growth-side));

    clip-path: polygon(
      var(--image-negative) var(--image-negative),
      calc(100% + var(--tile-growth-side)) calc(5% - var(--tile-growth-side)),
      calc(100% + var(--tile-growth-side)) calc(95% + var(--tile-growth-side)),
      var(--image-negative) calc(100% + var(--tile-growth-side))
    );

    .image {
      scale: 1.1;
    }
  }
}

.image {
  transition: all var(--transition-duration) ease;

  object-position: center;
  object-fit: cover;
}

.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: var(--tile-inner-gap);
  padding: 0 var(--tile-inner-paddding);
}

.postInfo {
  display: flex;
  gap: 1rem;
}

.subtitle,
.date {
  opacity: 0.4;
}

.externalLink {
  margin-top: auto;
}
