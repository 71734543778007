@use 'styles/breakpoints' as *;

.tileContainer {
  display: block;
  min-width: 17.5rem;
  width: 17.5rem;
  height: auto;

  @include desktop {
    min-width: 33rem;
    width: 33rem;
    height: auto;
  }
}

.image {
  object-fit: cover;
  min-width: 0;
  max-width: 100%;
  object-position: center;
  transition: all var(--transition-duration) ease;
}

.imageContainer {
  position: relative;
  display: flex;
  min-height: 0;
  aspect-ratio: 1;

  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  transition: all var(--transition-duration) ease;

  &:hover {
    --image-negative: calc(-1 * var(--tile-growth-side));

    clip-path: polygon(
      var(--image-negative) var(--image-negative),
      calc(100% + var(--tile-growth-side)) calc(5% - var(--tile-growth-side)),
      calc(100% + var(--tile-growth-side)) calc(95% + var(--tile-growth-side)),
      var(--image-negative) calc(100% + var(--tile-growth-side))
    );

    .image {
      scale: 1.1;
    }
  }
}

.teamImage {
  aspect-ratio: 132 / 169;
}

.textContainer {
  display: flex;
  flex-direction: column;
  margin-top: 1.75rem;
  gap: 0.5rem;

  @include desktop {
    gap: 1rem;
    margin-top: 2.5rem;
  }

  span {
    word-break: break-word;
  }

  &.teamTextContainer {
    align-items: center;
  }

  &.newsTextContainer .casesTextContainer {
    align-items: flex-start;
  }
}

.newsTitleContainer {
  display: flex;
  flex-direction: row;
  gap: 0.75rem;
}

.tagsContainer {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  flex-wrap: wrap;

  & > div {
    max-width: -webkit-fill-available;
  }

  p {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
