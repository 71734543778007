@use './shared.module.scss' as *;
@use 'styles/breakpoints' as *;

.linkButton {
  @include defaultButtonStyle;

  display: flex;
  align-content: center;
  gap: 0.25rem;

  @include desktop {
    gap: 0.5rem;
  }

  &.inverted {
    color: var(--clr-white);
  }

  &.disabled {
    @include disabled;
  }
}
