@use 'styles/breakpoints' as *;

.formSuccesOverlay {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgba(255, 255, 255, 0.7);
  gap: 2.5rem;
  -webkit-backdrop-filter: blur(12px);
  backdrop-filter: blur(12px);
  z-index: 1;
  padding: 6.5rem 3rem;
  height: calc(100% + 5rem);
  width: calc(100% + 2.75rem);
  left: -1.375rem;
  bottom: -3.25rem;

  @include desktop {
    padding: 10rem;
    height: calc(100% + 6.25rem);
    width: calc(100% + 6.25rem);
    left: -3.125rem;
  }

  .formSuccessIcon {
    display: flex;
    padding: 1.5rem;
    border-radius: 62.4375rem;
    background: var(--clr-primary);
    color: var(--clr-white);
    margin-bottom: 2rem;
  }

  .formSuccesContent {
    display: flex;
    flex-direction: column;
    gap: 0.64rem;
    align-items: center;
    text-align: center;
  }
}
