.vacancyList {
  align-items: start;
  &.noRowGap {
    row-gap: 0;
  }
}

.buttonContainer {
  justify-content: center;
}
