@use 'styles/breakpoints' as *;

.share {
  display: grid;
  gap: 1.5rem;
  --button-gap: 1.25rem;

  @include desktop {
    --button-gap: 1.75rem;
    gap: 3rem;
  }
}

.buttons {
  display: flex;
  gap: var(--button-gap);
}
