@use 'styles/breakpoints' as *;

.heroWrapper {
  overflow: hidden;
  display: flex;
  flex-direction: column;

  &.fullHeight {
    height: 100svh;
  }

  &.marginBottom {
    margin-bottom: var(--slope-padding);
  }
}

.hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 12.75rem;
  height: 100%;
  flex: 1;
  justify-content: center;
  position: relative;
  padding-bottom: 8rem;

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    clip-path: polygon(
      0 calc(100% + 1px),
      100% calc(100% + 1px),
      100% calc(100% - var(--slope-height))
    );
    background-color: var(--clr-light-gray);
  }
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1rem;
  color: var(--clr-white);
  text-align: center;
  position: relative;
  width: 100%;
}

.homeHeroTitle {
  font-family: var(--neue-plak-font);
  font-weight: 400;
  text-transform: uppercase;
  font-size: var(--hero-title-font-size);
  line-height: 0.8;
  width: 100%;
}

.titleWrapper {
  position: relative;
  display: flex;
  overflow: hidden;
  width: 100%;
  justify-content: center;
  height: calc(var(--hero-title-font-size) * 0.8);
}

.heroSubTitle,
.heroTitle {
  display: block;
}

.heroTitle {
  position: absolute;
  white-space: pre-line;
  transition: all 750ms cubic-bezier(0.2, 0.7, 0.7, 1.15);
}

.heroSubTitle {
  margin-bottom: calc(var(--hero-title-font-size) * 0.1);
}

.subject {
  font-family: var(--inter-font);
  font-size: var(--hero-subject-font-size);
  max-width: 12.5rem;

  @include desktop {
    max-width: none;
  }
}

.title {
  font-family: var(--neue-plak-font);
  font-weight: 400;
  text-transform: uppercase;
  font-size: var(--hero-title-font-size);
  line-height: 0.8;
  text-align: center;
  margin-bottom: 3.5rem;
  white-space: pre-line;
}

.bottomBar {
  background-color: var(--clr-white);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--bottom-bar-pad);
  position: relative;
  z-index: 1;
}

.bottomBarMouseIcon {
  display: none;
  width: auto;
  height: var(--bottom-bar-icon-size);

  @include desktop {
    display: block;
  }
}

.bottomBarArrowIcon {
  display: block;
  width: var(--bottom-bar-icon-size);
  height: var(--bottom-bar-icon-size);

  @include desktop {
    display: none;
  }
}

.bottomBarText {
  font-family: var(--inter-font);
  font-size: 1.125rem;
  line-height: 1.125rem;
  padding-left: 1.25rem;
  color: var(--clr-dark-gray);
  position: relative;

  @include desktop {
    font-size: 1.5rem;
    line-height: 1.5rem;
  }
}

.webgl {
  position: absolute;
  inset: 0;
}
