@use 'styles/breakpoints' as *;

.container {
  display: flex;
  flex-direction: column;

  @include desktop {
    flex-direction: row;
    gap: 5rem;
  }
}

.form {
  --gap: 1.5rem;
  position: relative;
  display: grid;
  gap: var(--gap);

  @include desktop {
    --gap: 2.5rem;
    min-width: 54%;
  }
}

.name {
  display: grid;
  gap: var(--gap);

  @include desktop {
    grid-template-columns: (repeat(2, 1fr));
  }
}

.textBlock {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;

  .header {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
  }
}

.submit {
  @include desktop {
    justify-self: start;
  }
}
