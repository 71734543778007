@use 'styles/breakpoints' as *;

.webglSwirlLine {
  display: grid;
  place-content: center;
}

.canvas {
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
}

.text {
  color: var(--clr-white);
  text-align: center;
  font-family: var(--neue-plak-font);
  font-weight: 400;
  line-height: 0.75;
  text-transform: uppercase;
  font-size: 8rem;

  @include desktop {
    font-size: 14rem;
  }
}
