@use 'styles/breakpoints' as *;

.growingArrowButton {
  width: 3rem;
  height: 3rem;
  padding: 0;
  justify-content: center;
  position: relative;
  color: var(--text-color);
  transition: width var(--transition-duration) ease;
  overflow: hidden;
  align-items: center;
  display: flex;

  &:hover,
  &.active {
    width: 6rem;
  }

  @include desktop {
    width: 4rem;
    height: 4rem;

    &:hover,
    &.active {
      width: 8rem;
    }
  }
}

.growingArrowIcon {
  position: absolute;
  right: 0;
}
