@use 'styles/breakpoints' as *;

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.25rem;
  height: 1.25rem;

  @include desktop {
    width: 1.75rem;
    height: 1.75rem;
  }
}
