@use 'styles/breakpoints' as *;

.illustrationRowItem {
  --illustration-size: 9rem;
  --copy-gap: 1rem;

  display: grid;
  gap: 2rem;
  justify-items: center;
  text-align: center;

  @include desktop {
    --illustration-size: 15rem;
    --copy-gap: 2rem;

    gap: 4rem;
  }
}

.illustration {
  color: var(--clr-primary);
  height: var(--illustration-size);
}

.content {
  display: flex;
  flex-direction: column;
  gap: var(--copy-gap);
}
