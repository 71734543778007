@use 'styles/breakpoints' as *;

.uploadedFilesWrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .uploadedFileItem {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0.5rem 0.875rem 0.5rem 0.62rem;
    gap: 1.25rem;

    @include desktop {
      padding: 1.25rem 1.75rem 1.25rem 1.5rem;
      gap: 1.25rem;
    }

    .uploadedFileDesc {
      display: flex;
      flex-direction: column;
    }

    .uploadedFileType {
      display: flex;
      width: 2.75rem;
      height: 2.75rem;
      justify-content: center;
      align-items: center;
      padding: 0.5rem;
      border-radius: 0.25rem;
      background-color: var(--clr-neutral-subtle);
      color: var(--clr-neutral-subtle-alpha);

      @include desktop {
        width: 3.75rem;
        height: 3.75rem;
        padding: 0.75rem;
      }
    }

    .removeFileButton {
      margin: 0 0 0 auto;
    }
  }
}
