@use 'styles/breakpoints' as *;

.drawerHeader {
  position: sticky;
  top: 0;
  padding-top: 1rem;
  background-color: var(--clr-white);
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  border-bottom: 2px solid var(--clr-dark-gray-faded);

  @include desktop {
    padding-top: 0;
  }
}

.drawerHandle {
  width: 5.875rem;
  height: 5px;
  opacity: 50%;
  background-color: #1e1e1e33;
}

.drawerTitle {
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;

  @include desktop {
    padding: 2rem 4rem;
  }

  button {
    width: 2rem;
    height: 2rem;
    align-self: center;

    @include desktop {
      width: 2.5rem;
      height: 2.5rem;
    }

    svg {
      width: 1.5rem !important;
      height: 1.5rem !important;

      @include desktop {
        width: 2.5rem !important;
        height: 2.5rem !important;
      }
    }
  }
}

.drawerOverlay {
  position: fixed;
  background-color: rgb(0 0 0 / 0.6);
  inset: 0px;
  z-index: 21;
}
.drawerContent {
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  height: auto;
  max-height: 96%;
  display: flex;
  flex-direction: column;
  z-index: 22;
  background-color: var(--clr-white);

  &:focus-visible {
    outline: none;
  }

  @include desktop {
    max-height: 100%;
    left: auto;
    top: 0;
    width: 720px;
    height: 100%;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

.drawerForms {
  overflow: auto;
  padding: 1.75rem 0;

  @include desktop {
    padding: 3rem 4rem;
  }

  > div {
    flex-direction: column;
    gap: 1.75rem;

    @include desktop {
      gap: 3rem;
    }
  }
}

.flagButton {
  background-color: var(--clr-black);
  color: var(--clr-white);
  position: fixed;
  bottom: 3rem;
  right: 0;
  padding: 1rem 0.75rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  z-index: 1;
  cursor: pointer;

  @include desktop {
    padding: 1.5rem 1rem;

    bottom: 30%;
  }

  p {
    color: var(--clr-white);
    transform: rotate(180deg);
    height: 1rem;
    font-size: 1rem;
    writing-mode: vertical-lr;
    height: fit-content;
    display: flex;
    align-items: center;
    padding-bottom: 0.25rem;
    font-weight: 700;

    @include desktop {
      font-size: 1.5rem;
    }
  }

  svg {
    width: 1.5rem;
    height: 1.5rem;

    @include desktop {
      width: 2rem;
      height: 2rem;
    }
  }
}
