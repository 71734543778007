@use 'styles/breakpoints' as *;

.filters {
  --option-gap: 2rem;

  display: flex;
  flex-wrap: wrap;
  row-gap: 1.5rem;
  margin-left: -2rem;
  margin-right: -2rem;

  @include desktop {
    row-gap: 2rem;
    --option-gap: 1.5rem;

    justify-content: space-between;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}

.dropdown {
  position: relative;
  width: 100%;
  margin-left: 2rem;
  margin-right: 2rem;

  @include desktop {
    width: auto;
    margin-right: 0;
    margin-left: 0;
  }
}

.dropdownToggle {
  width: 100%;

  &.open {
    &:hover {
      &::after {
        clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
        height: 100%;
        inset: 0;
      }
    }
  }
}

.dropdownList {
  position: absolute;
  display: grid;
  width: 100%;
  border: var(--btn-border-size) solid var(--clr-dark-gray);
  z-index: 2;
}

.dropdownItem {
  overflow: hidden;
}

.dropdownButton {
  width: 100%;
  justify-content: center;

  &:hover {
    color: var(--clr-white);

    &::after {
      background-color: var(--clr-dark-gray);
      clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
    }
  }
}

.optionsWrapper {
  overflow-x: auto;
  padding-left: 2rem;
  padding-right: 2rem;
  width: 100vw;
  display: flex;

  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  @include desktop {
    width: auto;
    padding-left: 0;
    padding-right: 0;
    overflow-x: initial;
  }
}

.options {
  display: flex;
  gap: var(--option-gap);
  flex-wrap: nowrap;
}
